<template>
  <div class="gonghuoqianyue">
    <van-nav-bar
      title="签约"
      left-arrow
      @click-left="onClickLeft"
    />
    
    <div id="capture" :style="'overflow: hidden; margin: 10px 0; height:' + (screenWidth - (screenWidth * 0.4)) + 'px;'">
      <div id="qrcode" :style="'padding: 0 ' + (screenWidth * 0.2) + 'px;'"></div>
    </div>
    <div
      id="shwo"
      :style="'width:' + screenWidth + 'px;height: ' + (screenWidth - (screenWidth * 0.4)) + 'px;text-align: center; margin: 10px auto; display:none;'"
    >
      <img id="img" crossorigin="anonymous" style="margin: 0 auto;padding: 0;" />
    </div>
    <div class="btn">
      <van-cell :border="false">
        <template #title>
          请确保微信号是经营者或法人本人实名微信号
        </template>
        <template #label>
          如果您不是经营者或法人，请将二维码发给法人本人完成签约。
        </template>
      </van-cell>
      <van-button block round type="primary" @click="commition">点击完成签约</van-button>
      <van-button block round type="default" @click="getqianyue">已完成签约</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GonghuoQianyue",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      userinfo: {},
      qrcodeImg: "", // 默认没有二维码
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "签约");
    this.init()
  },
  methods: {
    init() {
      // 初始化方法
      let _that = null;
      _that = this;
      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common["Authorization"] = jwtStr;
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          console.log(response);
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;
            _that.showqrcode();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    showqrcode() {
      let _that = null;
      _that = this;
      _that.$vueqrcode;
      // 二维码链接
      _that.qrcodeImg = new _that.QRCode(document.getElementById("qrcode"), {
        width: _that.screenWidth * 0.6,
        height: _that.screenWidth * 0.6,
        text: _that.userinfo.merch_reg.gonghuo_qianyue, // 二维码地址
        colorDark: "#000000",
        colorLight: "#ffffff"
      });

      setTimeout(() => {
        const el = document.getElementById("capture"); //要截图的div  document.querySelector("#capture")

        _that
          .$html2canvas(el, {
            useCORS: true
          })
          .then(canvas => {
            // 生成图片
            // document.body.appendChild(canvas)
            document.getElementById("capture").style.display = "none";
            document.getElementById("shwo").style.display = "block";
            const tempSrc = canvas.toDataURL("image/png");
            console.log(tempSrc);
            document.getElementById("img").src = tempSrc;
          });
      }, 1000);
    },
    commition() {
      window.location.href = this.userinfo.merch_reg.gonghuo_qianyue;
    },
    onClickLeft() {
      this.$router.back();
    },
    // 查询
    getqianyue(){
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merch_qianyue",
          _that.$qs.stringify({
            regid: _that.userinfo.merch_reg.gonghuo_id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "签约成功");
            _that.$router.push({ name: "merchTishi", query: {pagetitle: "联盟店铺", shareid: _that.shareid, merchid: _that.merchid} });
          }else{
            _that.$toast(res.data.msg ? res.data.msg : "签约失败");
          }
          
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}
</script>
<style lang="less">
.gonghuoqianyue {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  #capture {
    overflow: hidden;
    position: relative;
    #qrcode {
      overflow: hidden;
      background-color: #fff;
    }
    #avatarimg {
      width: 60px;
      height: 60px;
      position: absolute;
    }
  }

  #shwo {
    overflow: hidden;
    #img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    padding: 20px;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
        color: #000;
        font-weight: bold;
      }
      .van-cell__label {
        text-align: left;
        font-weight: normal;
      }
    }
    .van-button {
      margin-top: 20px;
    }
  }
}
</style>